.container-zt-page {
    height: var(--content-height);
    display: flex;
    flex-direction: row;
    overflow: hidden;
}

.ztpage-left {
    display: flex;
    flex-direction: column;
    /* overflow-y: hidden; */
    height: var(--content-height);
    width: 100%;
    max-width: 30rem;
}

.ztpage-right {
    display: flex;
    flex-direction: column;
    flex-basis: 0;
    flex-grow: 1;
    max-width: var(--reading-width);
    height: var(--content-height);
}

@media (max-width: 576px) {
    .ztpage-right{
        position: fixed;
    }
}

.zt-table-selection {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    border: 1px grey solid;
}

.zt-table-selection .p-rowgroup-header {
    font-weight: bold;
    /* Bold text */
    text-transform: uppercase;
    /* Uppercase text */
}

.zt-table-selection thead {
    display: none;
}

.zt-table-selection .p-datatable-footer{
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: flex-end;
}

.zt-table-selection tr:has(.tr-pillar-1) {
    background: var(--color-p1) !important;
}

.zt-table-selection tr:has(.tr-pillar-2) {
    background: var(--color-p2) !important;
}

.zt-table-selection tr:has(.tr-pillar-3) {
    background: var(--color-p3) !important;
}

.zt-table-selection tr:has(.tr-pillar-4) {
    background: var(--color-p4) !important;
}

.zt-table-selection tr:has(.tr-pillar-5) {
    background: var(--color-p5) !important;
}

.zt-table-selection tr:has(.tr-pillar-6) {
    background: var(--color-p6) !important;
}

.zt-table-selection tr:has(.tr-pillar-7) {
    background: var(--color-p7) !important;
}

.zt-table-selection .tr-capability {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.zt-table-selection .p-datatable-header{
    padding: 0;
}

.hover-curser:hover{
    cursor: pointer;
}

.activity-multiselect .p-multiselect-trigger{
    display: none;
}

.activity-multiselect .p-multiselect-label.p-multiselect-items-label{
    display: flex;
    align-items: center;
    height: 100%;
}

/* .activity-multiselect.p-inputwrapper-filled .p-multiselect-label{
    padding: 0.75rem 0.75rem;
    transition: unset;
} */

/* .activity-multiselect .p-multiselect-label::after{
    content: '\e94c'; 
    font-family: 'PrimeIcons';
} */
