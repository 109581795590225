.btn-activity-edit{
    width: 1.7rem;
    height: 1.7rem;
    border: unset;
}

.activity-container{
    overflow-y: auto;
    height: 100%;
}

.activity-container .p-toggleable-content{
    display: flex;
    flex-grow: 1;
}

.activity-container .p-panel-content{
    display: table;
}

.activity-container.split{
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
}

.activity-container .nist-buttons{
    display: flex;
    gap: 0.25rem;
    flex-wrap: wrap;
}

.activity-container .nist-buttons .p-button{
    padding: 0.25rem;
    border: 1px solid #0000002e;
}

.act-header{
    font-size: medium;
    font-weight: bold;
    margin-bottom: 0.5rem;
    text-decoration: underline;
}

.activity-main ol{
    margin: unset;
}

.activity-main .grid{
    margin-bottom: 0.5rem;
}

.activity-main .p-panel-title{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.activity-main.hide{
    display: none;
}

.activity-main.show{
    display: flex;
}

.activity-main{
    display: flex;
    flex-direction: column;
    height: 100%;
}

.nist-control-link:hover{
    cursor: pointer;
    text-decoration: underline;
}

.nist-groupings-container{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 0.25rem;
}

.nist-pv-highlight{
    font-weight: bold;
    font-style: italic;
}