:root {
  --reading-width: 600px;
  --content-height: calc(100vh - 59px);
  --content-start-height: 59px;
  --width-survey: 800px;

  --color-p1: #E8F5D5;
  --color-p1-dark: hsl(88, 66%, 80%);
  /* Darkened by reducing lightness */

  --color-p2: #D2E9F8;
  --color-p2-dark: hsl(204, 67%, 70%);
  /* Darkened by reducing lightness */

  --color-p3: #F8ECB4;
  --color-p3-dark: hsl(50, 84%, 70%);
  /* Darkened by reducing lightness */

  --color-p4: #D0CECE;
  --color-p4-dark: hsl(0, 2%, 60%);
  /* Darkened by reducing lightness */

  --color-p5: #FBD7AF;
  --color-p5-dark: hsl(33, 88%, 70%);
  /* Darkened by reducing lightness */

  --color-p6: #CCD6EA;
  --color-p6-dark: hsl(220, 41%, 70%);
  /* Darkened by reducing lightness */

  --color-p7: #E1D4FD;
  --color-p7-dark: hsl(261, 94%, 80%);
  /* Darkened by reducing lightness */

  /* Option 1 */
--color-v1: #FADADD;
--color-v1-dark: hsl(340, 80%, 85%); /* Darkened by reducing lightness */

/* Option 2 */
--color-v2: #F9D3CD;
--color-v2-dark: hsl(10, 80%, 85%); /* Darkened by reducing lightness */

/* Option 3 */
--color-v3: #D0F0E0;
--color-v3-dark: hsl(150, 60%, 85%); /* Darkened by reducing lightness */

/* Option 4 */
--color-v4: #FADAB1;
--color-v4-dark: hsl(30, 85%, 85%); /* Darkened by reducing lightness */

/* Option 5 */
--color-v5: #D0F0F0;
--color-v5-dark: hsl(180, 60%, 85%); /* Darkened by reducing lightness */

/* Option 6 */
--color-v6: #E8D8F8;
--color-v6-dark: hsl(270, 80%, 85%); /* Darkened by reducing lightness */

/* Option 7 */
--color-v7: #FAD4C0;
--color-v7-dark: hsl(20, 80%, 85%); /* Darkened by reducing lightness */


  --color-secondary: #64748b;
  --color-secondary-a: #525c71;

  --color-badge-success: #22c55e;
  --color-badge-secondary: #64748b;
  --color-badge-warning: #f97316;
  --color-badge-info: #0ea5e9;
  --color-badge-danger: #ef4444;
  /* --color-badge-help: #a855f7; */
  --color-badge-help: #6366f1;
}

body {
  overflow-y: hidden;
}

.app-body {
  height: var(--content-height);
}

.App {
  box-sizing: border-box;
  margin: 0;
  background: white;
  height: 100vh;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
}

.App-logo {
  height: 6vmin;
  pointer-events: none;
}

.link-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  display: inline;
  margin: 0;
  padding: 0;
}

.link-button:hover,
.link-button:focus {
  text-decoration: none;
}

.main-container {
  padding-top: 48px;
  display: flex;
  flex-direction: column;
  height: calc(100% - 64px);
}

.todo-items-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
}

.auth-card {
  display: flex;
  flex-direction: column;
  padding: 24px;
  overflow-y: auto;
}

.auth-form {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

@keyframes shake {
  0% {
    transform: translateX(0);
  }

  25% {
    transform: translateX(-10px);
  }

  50% {
    transform: translateX(10px);
  }

  75% {
    transform: translateX(-10px);
  }

  100% {
    transform: translateX(0);
  }
}

.shake {
  animation: shake 0.5s;
}


.app-body::-webkit-scrollbar {
  width: 8px;
}

.app-body::-webkit-scrollbar-track {
  background: #e0e0e0;
}

.app-body::-webkit-scrollbar-thumb {
  background-color: rgba(128, 128, 128, 0.363);
  border: 2px solid transparent;
  border-radius: 4px;
  background-clip: padding-box;
}

.list-gap>li {
  margin-bottom: 10px;
}