.btn-nist-enhance:hover{
    cursor: pointer;
    text-decoration: underline;
}

.nist-group-container{
    border: solid rgba(0, 0, 0, 0.411) 1px;
    border-radius: 0.5rem;
    width: 49%;
    padding: 0.5rem;
}

.nist-group-container .family-name{
    margin-bottom: 0.5rem;
    font-size: small;
    font-weight: bold;
}

.p-overlay-badge .p-badge{
    border: solid #0000002e 1px;
    background-color: var(--color-p2);
    line-height: 0.5rem;
    height: 0.5rem;
    min-width: 0.5rem;
}

.nist-panel.hide{
    display: none;
}

.nist-panel .p-panel-title{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
    width: 100%;
}


.nist-panel{
    /* position: absolute; */
    display: flex;
    flex-direction: column;
    height: 100%;
    /* max-width: var(--reading-width); */
}

.nist-panel .p-toggleable-content, .activity-main .p-toggleable-content{
    overflow-y: auto;
}

/* .pv-card{
    position: relative;
    border: solid black 1px;
    border-radius: 0.5rem;
    padding: 1rem;
    margin: 0.5rem 0 1rem 0;
    background: rgba(255, 255, 87, 0.123);
} */

.overlay-fieldset .p-fieldset-legend{
    padding: 0.5rem;
    color: white;
    font-size: small;
}

.overlay-fieldset .p-fieldset-content{
    padding: 0.5rem;
}

/* Overlay: Classified */
.overlay-fieldset.ovt-classified .p-fieldset-legend{
    background-color: var(--green-300);
}

/* .overlay-fieldset.ovt-class{
    background-color: var(--green-100);
} */

/* Overlay: Intel */
.overlay-fieldset.ovt-intel .p-fieldset-legend{
    background-color: var(--blue-300);
}

/* .overlay-fieldset.ovt-intel{
    background-color: var(--blue-100);
} */

/* Overlay: Threshold Guidance */
.overlay-fieldset.ovt-cnss .p-fieldset-legend{
    background-color: var(--yellow-300)
}

/* .overlay-fieldset.ovt-thg{
    background-color: var(--yellow-100)
} */

/* Overlay: Zero Trust */
.overlay-fieldset.ovt-zt .p-fieldset-legend{
    background-color: var(--purple-300);
}

/* .overlay-fieldset.ovt-zt{
    background-color: var(--purple-100);
} */

.applicability-table{
    width: 100%;
    text-align: center;
}

.applicability-table td{
    border: 1px solid #e5e7eb;
}