.ztkm-dropdown{
    position: relative;
    display: inline-block;
}

.ztkm-dropdown-content{
    display: none;
    position: absolute;
    flex-direction: column;
    gap: 0.5rem;
    padding: 1rem;
    background-color: #f9f9f9;
    /* min-width: 160px; */
    white-space: nowrap;
    box-shadow: 0px 8px 16px rgba(0,0,0,0.2);
    z-index: 10;
}

.ztkm-dropdown:hover .ztkm-dropdown-content {
    display: flex;
}

.ztkm-dropdown-content a{
    text-decoration: unset;
    color: black;
}

.ztkm-dropdown-content a:hover{
    text-decoration: underline;
}

.ztkm-dropdown-content a.active{
    text-decoration: underline;
}