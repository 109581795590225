.zt-inline-custom .p-inplace-content-close{
    background-color: white;
    border-color: var(--color-secondary-a);
    color: var(--color-secondary-a);
}

.zt-inline-custom .p-inplace-content-close{
    width: unset;
    padding: 0.25rem;
}

.zt-inline-custom .p-inplace-display{
    display: flex;
    gap: 0.25rem;
    width: fit-content;
    align-items: center;
}

.zt-capability-component .select-capability{
    border-color: var(--color-badge-success);
}

.zt-capability-component .select-capability .p-dropdown-label{
    color: var(--color-badge-success);
}