.project-selection {
    display: flex;
    justify-content: center;
}

.project-selection .p-dropdown-item option[aria-selected="true"] {
    background: rgb(25, 91, 112);
    color: white;
}

#addP-tags {
    flex-grow: 1;
}

#addP-tags .p-inputtext {
    width: 100%;
}