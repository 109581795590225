.p-steps-item.p-highlight .p-steps-title{
    text-decoration: underline;
}

.p-progressbar-value{
    overflow: visible;
}

.outlined-text {
    color: white; /* The main text color */
    text-shadow: 
        -1px -1px 0 #000,  
         1px -1px 0 #000,
        -1px  1px 0 #000,
         1px  1px 0 #000; 
}