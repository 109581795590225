/* .act-save-popup{
    padding: 1rem;
    background-color: var(--color-badge-success);
    color: white;
    border: 1px solid var(--color-badge-secondary);
    border-radius: 0.5rem;
} */

.act-save-popup {
    z-index: 5;
    width: fit-content;
    align-self: center;
}

.outcome-toggle .p-togglebutton.p-highlight .p-button {
    background-color: var(--color-badge-success);
    border-color: var(--color-badge-success);
}

.outcome-toggle .p-togglebutton .p-button {
    background-color: var(--color-badge-secondary);
    border-color: var(--color-badge-secondary);
    color: white;
}

.outcome-toggle .p-togglebutton .p-button span{
    color: white;
}

.at-ztac-mark{
    color: #64748b;
    border: 1px solid #64748b;
    background-color: transparent;
    padding: 0 0.25rem;
    border-radius: 50%;
    margin-right: 0.5rem;
}

.at-ztac-mark.S{
    color: #22c55e;
    border-color: #22c55e;
}