.navItem-icon {
    /* display: inline-block; */
    width: 1.5rem;
    height: 1.5rem;
    color: black;
}

.navItem-text {
    /* position: absolute; */
    font-size: 0.7rem;
    color: black;
    text-decoration: none;
    /* Remove underline */
}

.navApp-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: black;
    cursor: pointer;
}

.navApp-item:hover {
    color: rgb(80, 80, 80);
    transform: translateY(-2px);
}

.navApp-item.active {
    border-bottom: 3px solid gold;
}

.navApp-item:visited {
    color: black;
    /* Ensure the visited color is also black */
    text-decoration: none;
    /* Remove underline for visited links */
}