.sb-survey-btn{
    position: fixed;
    bottom: 0.5rem;
    left: 0.5rem;
}

.survey-sidebar-content .p-selectbutton .p-button{
    padding: 0.5rem 0.75rem;
}

.survey-sidebar-content .p-selectbutton .p-button.p-highlight{
    background: var(--color-secondary);
    border-color: var(--color-secondary-a);
}