#ZTPillars g {
    cursor: pointer;
}

#ZTPillars g:hover {
    stroke: black;
    stroke-width: 0.04rem;
}

.pillar-svg-container {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.pillar-svg-container > svg {
    width: 100%;
    height: auto;
}