.container-informational{
    height: 100%;
    display: flex;
}

.container-informational-content{
    padding: 1rem;
    overflow-y: auto;
    height: 100%;
    width: 100%;
}
