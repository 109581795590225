.container-survey {
    display: flex;
    flex-direction: row;
    height: 100%;
}

.container-survey-sidebar{
    min-width: 200px;
    overflow-y: auto;
    z-index: 9;
}

.container-survey-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    /* padding-top: 1rem; */

    max-width: var(--width-survey);
    /* Maximum width for readability */
    width: 100%;
    /* Full width for smaller screens */
    /* margin: 0 auto; */
    /* Center the content */
    /* padding: 0 20px; */
    /* Padding for breathing space */
}

.container-survey-content .p-metergroup-label-list-horizontal{
    overflow-x: auto;
}

.activity-component .p-toggleable-content {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    height: 75vh;
}

.survey-cap{
    text-align: center;
    font-weight: bold;
    font-size: larger;
    font-family: var(--font-family);
    border: 1px solid rgb(73, 73, 73);
    border-radius: 0.25rem;
}

.survey-cap.inView{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    text-align: left;
    padding: 0.5rem 1rem;
}

.survey-activity-content .p-dropdown-label.p-inputtext{
    padding: 0;
}

.survey-dropdown .p-dropdown-items-wrapper{
    max-height: unset !important;
}

.survey-activity-content .p-dropdown-trigger{
    width: fit-content;
    padding: 0 0.25rem 0 0;
}

.survey-inplace .p-inplace-display{
    border: 1px solid rgb(73, 73, 73);
    border-radius: 0.25rem;
    padding: 0.25rem;
}


/* Customize the scroll bar for a specific element */

.container-survey-content .p-toggleable-content::-webkit-scrollbar {
    width: 8px;
}

.container-survey-content .p-toggleable-content::-webkit-scrollbar-track {
    background: #e0e0e0;
}

.container-survey-content .p-toggleable-content::-webkit-scrollbar-thumb {
    background-color: rgba(128, 128, 128, 0.363);
    border: 2px solid transparent;
    border-radius: 4px;
    background-clip: padding-box;
}